import * as React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import "./Header.css";

const Header: React.FC = () => {
  return (
    <div className="Header">
      <ParallaxBanner
        className="your-class"
        layers={[
          {
            image: "/images/suede_pic2.png",
            amount: 0.5,
            children: <div className="glu-header-darken" />
          }
        ]}
        style={{
          height: "400px"
        }}
      >
        <div className="parallaxChildren">
          <div className="dropShadowDown" />
          <div className="headerTextContainer">
            <h1>GLU DJ Collective</h1>
            <h2>Hip-Hop | Afrobeats | Dancehall | Funk</h2>
          </div>
          <div className="dropShadowUp" />
        </div>
      </ParallaxBanner>
    </div>
  );
};

export default Header;
