import * as React from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import "./Events.css";
import { Component } from "react";

export default class Events extends Component {
  goToSuedeJan() {
    window.open(
      "https://www.eventbrite.com/e/suede-house-party-presented-by-glu-collective-tickets-89336619233",
      "_blank"
    );
  }

  render() {
    return (
      <div className="Events">
        <Container>
          <Row style={{ paddingTop: 50 }}>
            <Col className="eventCardCol">
              <h1>Events</h1>
            </Col>
          </Row>

          <Row>
            <Col className="eventCardCol">
              <Image
                className="eventImage"
                src="/images/suede-jan2020-flyer.png"
                width="100%"
              />
            </Col>
            <Col className="suedeCol">
              <Row>
                <h1>Suede</h1>
              </Row>
              <Row>Saturday Jan 18, 2020 | 10pm - Late</Row>
              <Row>Bushwick Public House - Downstairs</Row>
              <Row>1288 Myrtle Ave, Brooklyn, NY 11221</Row>
              <Row>
                <Button
                  className="eventButton"
                  variant="primary"
                  onClick={this.goToSuedeJan}
                >
                  RSVP
                </Button>
              </Row>
            </Col>
          </Row>

          <div className="dummy" />
        </Container>
      </div>
    );
  }
}
