import * as React from "react";
import { Navbar } from "react-bootstrap";
import { ParallaxProvider } from "react-scroll-parallax";
import Events from "./components/Events";
import Header from "./components/Header";
import "./App.css";

export default function App() {
  return (
    <div className="App">
      <Navbar className="glu-nav" variant="dark">
        <Navbar.Brand href="#home" className="glu-logo">
          <img
            alt=""
            src="./images/glu_logo_150.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          GLU Collective
        </Navbar.Brand>
      </Navbar>
      <ParallaxProvider>
        <Header />
      </ParallaxProvider>
      <Events />
    </div>
  );
}
